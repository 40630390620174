/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from "react";
import cn from "classnames";
import FlexibleSpaceModal from "components/componentLibrary/Modals/FlexibleSpaceModal";
import { track, eventCategories, analyticsEvents } from "util/analytics";

import styles from "./styles.module.scss";

type Props = {
  dismissible: boolean;
};

const FlexBanner = ({ dismissible = false }: Props) => {
  const [showSignUpModal, toggleSignUpModal] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const parentEl = useRef<HTMLDivElement>(null);
  const hideMarketingBanner =
    typeof window !== "undefined" &&
    localStorage.getItem("hideMarketingBanner");

  const trackClose = () =>
    track(analyticsEvents.flex_modal_close, {
      category: eventCategories.flex_banner,
      label: "",
    });

  const trackOpen = () =>
    track(analyticsEvents.flex_modal_open, {
      category: eventCategories.flex_banner,
      label: "",
    });

  const onClick = () => {
    trackOpen();
    toggleSignUpModal(true);
  };

  if (dismissed) return null;
  if (dismissible && hideMarketingBanner === "true") return null;
  return (
    <>
      <p
        onClick={onClick}
        ref={parentEl}
        className={cn(styles.banner, "flex-banner")}
        onAnimationEnd={() => setDismissed(true)}
      >
        <span className={styles.link}>
          Get $2000 cash back on your office space!
        </span>
        <button className={`btn-primary ${styles.button}`}>Redeem Offer</button>
      </p>
      {showSignUpModal && (
        <FlexibleSpaceModal
          onClose={() => {
            trackClose();
            toggleSignUpModal(false);
          }}
          numberOfEmployees={0}
          neighborhoods={null}
          submarkets={[]}
          city="new-york"
        />
      )}
    </>
  );
};

export default FlexBanner;
