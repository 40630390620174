import { gql, useApolloClient } from "@apollo/client";

import TopPostCard from "./TopPostCard";

import styles from "./style.module.scss";

export const TOP_POSTS_QUERY = gql`
  query TopPostsQuery($numberOfPosts: Int, $tag: Int) {
    cmsPosts(numberOfPosts: $numberOfPosts, tag: $tag) {
      title
      image
      link
    }
  }
`;

export const TOP_POSTS_QUERY_OPTIONS = {
  variables: {
    numberOfPosts: 3,
    tag: 185,
  },
};

const BetterCompanyCultureSection = () => {
  const apolloClient = useApolloClient();

  /*
   * This is being fetched and stored in getStaticProps
   * of the home page and so we only need to read from that cache
   */
  const topPostsQueryResponse = apolloClient.readQuery({
    query: TOP_POSTS_QUERY,
    ...TOP_POSTS_QUERY_OPTIONS,
  });

  return (
    <section className={styles.betterCompanyCultureSection}>
      <div className="inset">
        <h2>For a better company&nbsp;culture</h2>
        <div className={styles.topPostCardContainer}>
          {topPostsQueryResponse?.cmsPosts.map((post, index) => (
            <TopPostCard
              key={post.title}
              className={index === 0 ? "" : "desktop-only"}
              topPost={post}
            />
          ))}
        </div>
        <div className={styles.readMore}>
          <a className="btn-primary" href="https://www.squarefoot.com/blog/">
            Read all articles
          </a>
        </div>
      </div>
    </section>
  );
};

export default BetterCompanyCultureSection;
