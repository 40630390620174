import PropTypes from "prop-types";
import React from "react";
import styles from "./style.module.scss";

const ColumnalTextDisplay = ({ className, title, children }) => (
  <div className={`${styles.container} ${className}`}>
    <div className={styles.inner}>
      <h3>{title}</h3>
      {children}
    </div>
  </div>
);

ColumnalTextDisplay.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.node,
};

ColumnalTextDisplay.defaultProps = {
  children: null,
  className: "",
  title: null,
};

export default ColumnalTextDisplay;
