import ColumnalTextDisplay from "components/ColumnalTextDisplay";

import styles from "./style.module.scss";

const ValuePropSection = () => (
  <section className="inset">
    <h1>
      Commercial&nbsp;Real Estate&nbsp;and&nbsp;Office Space&nbsp;for&nbsp;Rent
    </h1>
    <div className={styles.valueProps}>
      <ColumnalTextDisplay title="Access to tons of available listings.">
        <p>
          Comprehensive listings enable you to see what&rsquo;s on the market.
          Our brokers are on standby to answer any questions and help guide you
          through the process.
        </p>
        <a href="/profile/">View Listings</a>
      </ColumnalTextDisplay>
      <ColumnalTextDisplay title="Trusted partners who work for you.">
        <p>
          Our brokers spend 100% of their time doing deals, so they can focus on
          helping you find a space you love. Not hunting for their next
          commission.
        </p>
        <a href="/about/#how-it-works">How It Works</a>
      </ColumnalTextDisplay>
      <ColumnalTextDisplay title="Goodbye printouts, hello smart tools.">
        <p>
          We provide simple tools like a Space Calculator to help you refine
          your requirements and plan your search.
        </p>
        <a href="/office-space-calculator">View Tools</a>
      </ColumnalTextDisplay>
    </div>
  </section>
);

export default ValuePropSection;
