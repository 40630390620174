// Simulate Rails String.titleize
export function titleize(str: string) {
  return str
    .toLowerCase()
    .replace(/[-_\s]+/g, " ")
    .replace(/(?:^|\s|-)\S/g, (chr) => chr.toUpperCase());
}
// Convert a string into URL-safe, human-readable representation (like Rails String.parameterize)
export function parameterize(str = "", separator = "-") {
  return str
    .replace(/[^a-z0-9\-_]+/gi, separator)
    .replace(new RegExp(`${separator}{2,}`), separator)
    .replace(new RegExp(`^${separator}|${separator}$`), "")
    .toLowerCase();
}
// Format numbers with commas as a thousands separator
// Number.toLocaleString() is, of course, broken in Safari, so this is necessary
export function formatNumber(num: number) {
  const decimalParts = (num || 0).toString().split(".");
  decimalParts[0] = decimalParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return decimalParts.join(".");
}
// Format detail values for display
// Booleans show as ︎︎✔︎ or ✗, numbers are rounded, otherwise just cast to string
export function formatDetailValue(detailValue: string | boolean | number) {
  if (detailValue === "true" || detailValue === true) {
    return "✔︎";
  }
  if (detailValue === "false" || detailValue === false) {
    return "✗";
  }
  if (typeof detailValue === "number") {
    return (Math.round(detailValue * 100) / 100).toString();
  }
  return detailValue.toString();
}

export function formatUSPhoneNumber(phone: string) {
  const phoneString = phone ? phone.toString() : "";
  let regex: RegExp;
  if (phoneString.length) {
    // If there are non-digits, don't try to be smart
    if (phoneString.match(/\D/)) {
      return phoneString;
    }
    // It might have a leading 1 for a US phone number
    if (phoneString.length === 11 && phoneString.charAt(0) === "1") {
      regex = /(\d)(\d{3})(\d{3})(\d{4})/;
      return phoneString.replace(regex, "$1-$2-$3-$4");
    }
    // Common US case
    if (phoneString.length === 10) {
      regex = /(\d{3})(\d{3})(\d{4})/;
      return phoneString.replace(regex, "$1-$2-$3");
    }
  }
  // We shouldn't get here but if we do, return what we were given as a string
  return phoneString;
}

export function constantCase(str: string) {
  return str.toUpperCase().replace(/[\s-]+/g, "_");
}

export const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

export const sanitizeUrl = (string: string) =>
  string.replace(/[^-A-Za-z0-9+&@#/%?=~_|!:,.;\\(\\)\s]/g, "");

export const isUuid = (string: string) =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    string
  );
