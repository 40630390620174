import { useState, useEffect } from "react";
import { gql, useQuery } from "@apollo/client";

const CITIES_QUERY = gql`
  query cities {
    cities(count: 1000, includeAll: true) {
      id
      name
      parameterizedName
    }
  }
`;

const useIsCityInDb = (city) => {
  // const apolloClient = initializeApollo();
  const { data, loading } = useQuery(CITIES_QUERY);

  const [isCityInDb, setIsCityInDb] = useState(false);

  useEffect(() => {
    const cityInDB =
      data?.cities?.some(
        ({ parameterizedName }) => parameterizedName === city
      ) || false;
    setIsCityInDb(cityInDB);
  }, [loading, data, city]);

  return isCityInDb;
};

export default useIsCityInDb;
