import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Typed from "typed.js";

const propTypes = {
  text: PropTypes.string.isRequired,
  typeSpeed: PropTypes.number,
  startDelay: PropTypes.number,
  showCursor: PropTypes.bool,
  onTypingComplete: PropTypes.func,
};
const defaultProps = {
  typeSpeed: 65,
  startDelay: 500,
  showCursor: false,
  onTypingComplete: () => {},
};

class Typer extends PureComponent {
  componentDidMount() {
    const { text, typeSpeed, startDelay, showCursor, onTypingComplete } =
      this.props;
    const options = {
      strings: [text],
      typeSpeed,
      startDelay,
      showCursor,
      onTypingComplete,
    };

    this.typed = new Typed(this.element, options);
  }

  componentWillUnmount() {
    if (this.typed) {
      this.typed.destroy();
    }
  }

  render() {
    return (
      <span
        ref={(element) => {
          this.element = element;
        }}
      />
    );
  }
}

Typer.propTypes = propTypes;
Typer.defaultProps = defaultProps;
export default Typer;
