import React, { FormEvent, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { EMAIL_REGEX } from "util/constants";
import {
  areaQueryWithCmsContent_area_Market_neighborhoods,
  areaQueryWithCmsContent_area_Neighborhood_market_neighborhoods,
} from "types/pages/office-space/m/areaQueryWithCmsContent";
import { publicSearchPageQuery_city_market_neighborhoods } from "types/pages/office-space/publicSearchPageQuery";
import useIsCityInDb from "hooks/useIsCityInDb";
import useToast from "hooks/useToast";
import { constantCase } from "util/strings";
import { useCurrentUser } from "hooks";

import BaseModal from "../Modal";
import styles from "./styles.module.scss";
import SignInModal from "../SignInModal";

const RENTER_ONBOARDING_MUTATION = gql`
  mutation RenterOnboardingSavedSearchMutation(
    $email: String!
    $phone: String
    $cityOfInterest: CityName
    $marketOfInterest: ID
    $numberOfEmployees: Int
    $squareFeet: Int
    $neighborhoodIds: [ID]
    $neighborhoodNames: [String]
    $signUpType: String
    $areaExternalIds: [ID]
    $source: String
  ) {
    renterOnboarding(
      input: {
        renter: { phone: $phone, email: $email, signUpType: $signUpType }
        cityOfInterest: $cityOfInterest
        marketOfInterest: $marketOfInterest
        areaExternalIds: $areaExternalIds
        onboardingInfo: {
          neighborhoodIds: $neighborhoodIds
          numberOfEmployees: $numberOfEmployees
          squareFeet: $squareFeet
          neighborhoodNames: $neighborhoodNames
          source: $source
        }
      }
    ) {
      onboardingId
      created
      neighborhoodNames
      currentUser {
        id
        salesforceId
        email
        uuid
        firstName
        lastName
        phone
        e164Phone
        companyName
        displayName
        isRenter
        isBroker
        isVerified
        isAdmin
        hasPassword
        featureFlags
        profilePicURL
        brokerMarket {
          id
          name
          state
        }
        completedUserTasks {
          id
          task
        }
        confirmed
        unconfirmedEmail
        cityOfInterest
        marketOfInterest {
          id
          name
          position {
            lat
            lng
          }
        }
        tours {
          id
          date
        }
        latestCampaign {
          id
          uuid
          listings {
            id
          }
          surveys {
            nodes {
              id
              externalId
            }
          }
          currentActiveRequirements(setByUser: true) {
            id
            neighborhoods {
              id
              name
            }
            minPsf
            maxPsf
            minSquareFeet
            maxSquareFeet
            minMonthlyBudget
            maxMonthlyBudget
            numberOfEmployees
            minSqft: minSquareFeet
            maxSqft: maxSquareFeet
            maxPricePerSquareFoot: maxPsf
            minPricePerSquareFoot: minPsf
            maxMonthlyPrice: maxMonthlyBudget
            minMonthlyPrice: minMonthlyBudget
            leaseTerm
            areas {
              id
              externalId
              name
            }
            market {
              id
              name
              position {
                lat
                lng
              }
            }
          }
        }
      }
      errors {
        auth
      }
    }
  }
`;

type Props = {
  onClose: () => void;
  onConfirm?: (user?: any) => void;
  market?: string;
  city?: string;
  numberOfEmployees: number;
  neighborhoods:
    | null
    | (
        | publicSearchPageQuery_city_market_neighborhoods
        | areaQueryWithCmsContent_area_Market_neighborhoods
        | areaQueryWithCmsContent_area_Neighborhood_market_neighborhoods
      )[];
  submarkets: { id: string; externalId: string }[];
};

const FlexibleSpaceModal = ({
  onClose,
  onConfirm = () => {},
  city,
  submarkets,
  market,
  neighborhoods,
  numberOfEmployees,
}: Props) => {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showLogin, setShowLogin] = useState(false);
  const { login } = useCurrentUser();

  const [onboardRenter] = useMutation(RENTER_ONBOARDING_MUTATION);
  const { showToast } = useToast();
  const isCityInDB = useIsCityInDb(city);

  const submarketIds = submarkets.map(({ externalId }) => externalId);
  const neighborhoodIds =
    neighborhoods?.map(({ externalId }) => externalId) || [];
  const areaExternalIds = [...submarketIds, ...neighborhoodIds];

  const onSubmit = async () => {
    const variables = {
      email,
      phone,
      marketOfInterest: market,
      cityOfInterest: city && !market && isCityInDB ? constantCase(city) : null,
      numberOfEmployees,
      areaExternalIds,
      squareFeet: numberOfEmployees * 150,
      signUpType: "Public Search",
      source: "flex-space-card",
    };

    const { data } = await onboardRenter({ variables });
    if (
      !data?.renterOnboarding?.created &&
      data?.renterOnboarding?.errors?.auth?.includes(
        "Login and password do not match."
      )
    ) {
      showToast("Account already exists.");
      setShowLogin(true);
    } else if (data?.renterOnboarding?.currentUser) {
      await login();
      onConfirm(data.renterOnboarding.currentUser);
      showToast("Your interest in Flexible Spaces has been registered");
    }
  };
  return (
    <>
      <BaseModal
        basicStyles
        onClose={onClose}
        containerClassName={styles.container}
        className={styles.modal}
      >
        <h1>Sign up to view inventory of flexible listings:</h1>
        <form
          id="saveSearchForm"
          data-testid="saveSearchForm"
          method="POST"
          className={styles.saveSearchForm}
          onSubmit={(event: FormEvent<HTMLElement>) => {
            event.preventDefault();
            onSubmit();
          }}
        >
          <input
            data-testid="emailInput"
            type="email"
            name="saveSearchEmail"
            placeholder="Your email address*"
            pattern={EMAIL_REGEX}
            title="Email provided is not a valid email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            required
          />
          <input
            type="tel"
            name="saveSearchPhone"
            placeholder="Phone"
            pattern="^[ ()+*#-]*(?:\d[ ()+*#-]*){7,40}$"
            value={phone}
            onChange={(e) => setPhone(e.currentTarget.value)}
            required
          />
          <div className={styles.buttonContainer}>
            <button
              data-jest="saveSearchCancelButton"
              className={`btn-text ${styles.closeButton}`}
              type="button"
              onClick={onClose}
            >
              CANCEL
            </button>
            <button
              type="submit"
              data-testid="saveSearchSubmitButton"
              className={`btn-outline ${styles.saveSearchButton}`}
            >
              CONTINUE
            </button>
          </div>
        </form>
      </BaseModal>
      {showLogin && (
        <SignInModal
          existingEmail={email}
          onSuccess={() => {
            setShowLogin(false);
          }}
          onClose={() => {
            setShowLogin(false);
          }}
        />
      )}
    </>
  );
};

export default FlexibleSpaceModal;
