const campaignSearchPath = "/tourbook/campaigns";
const toursPath = "/tourbook/tours";
const surveysPath = "/tourbook/surveys";
const clientSearchPath = "/tourbook/search";

export default function homepageReplacementPath(user) {
  if (user?.isBroker) {
    return campaignSearchPath;
  }

  if (user?.isVerified && user?.isRenter && user?.tours.length > 0) {
    return toursPath;
  }

  if (user?.isVerified && user?.isRenter) {
    return `${surveysPath}/${
      user?.latestCampaign?.surveys?.nodes?.[0]?.externalId || ""
    }`;
  }

  if (user?.isRenter) {
    return clientSearchPath;
  }

  return null;
}
